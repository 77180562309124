import { FetchPolicy } from '@apollo/client';
import i18n from 'i18next';
import * as PIXI from 'pixi.js';
import { Container } from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import {
  setBetAmount,
  setBrokenGame,
  setCoinValue,
  setCurrency,
  setCurrentBonus,
  setFreeRoundBonus,
  setGameMode,
  setIsBuyFeaturePurchased,
  setIsFreeRoundBonus,
  setIsProcessToGame,
  setReplayBet,
  setUserLastBetResult,
  setWinAmount,
} from '../../gql/cache';
import client from '../../gql/client';
import { getBetAmountGql, getUserGql, getWinAmountGql } from '../../gql/query';
import {
  calcBottomContainerHeight,
  countCoins,
  formatNumber,
  isMobileDevice,
  isMobilePortrait,
  normalizeBalance,
  normalizeCoins,
  showCurrency,
  updateTextScale,
} from '../../utils';
import ViewContainer from '../components/container';
import { bottomContainerTextStyle, eventManager } from '../config';

import InfoBtn from './infoBtn';

class BottomContainer extends ViewContainer {
  private isInitialized = false;

  private rect: PIXI.Graphics;

  private infoBtn: Container;

  private balance: PIXI.Text;

  private win: PIXI.Text;

  private bet: PIXI.Text;

  private freeRoundsAmount: PIXI.Text;

  // TODO have to be refactored
  private currency = 'FUN';

  private maxFontHeight: number;

  private maxFontWidth: number;

  constructor() {
    super();
    this.rect = new PIXI.Graphics();
    this.addChild(this.rect);
    this.infoBtn = new InfoBtn();
    this.addChild(this.infoBtn);
    this.maxFontHeight = 30;
    this.maxFontWidth = 300;
    this.balance = new PIXI.Text(this.createText('balance', 0), bottomContainerTextStyle);
    this.balance.anchor.set(0, 0.5);

    this.freeRoundsAmount = new PIXI.Text(
      this.createText('freeRounds', setFreeRoundBonus().rounds),
      bottomContainerTextStyle,
    );

    this.freeRoundsAmount.anchor.set(0, 0.5);
    this.freeRoundsAmount.visible = false;
    this.addChild(this.freeRoundsAmount);

    if (!setReplayBet()) {
      this.addChild(this.balance);
    }
    this.win = new PIXI.Text(
      this.createText(
        'win',
        formatNumber(
          this.currency,
          normalizeCoins(
            countCoins({
              totalAmount: setWinAmount(),
            }),
          ),
          true,
        ),
      ),
      bottomContainerTextStyle,
    );
    this.win.anchor.set(0.5, 0.5);
    this.addChild(this.win);
    this.bet = new PIXI.Text(
      this.createText(
        'bet',
        formatNumber(this.currency, normalizeCoins(countCoins({ totalAmount: setBetAmount() })), true),
      ),
      bottomContainerTextStyle,
    );
    this.bet.anchor.set(1, 0.5);
    this.addChild(this.bet);

    if (setCurrency() !== '' && setCurrency() !== 'FUN') {
      this.currency = setCurrency();
    }

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.HIDE_WIN_LABEL, () => {
      if (setIsFreeRoundBonus()) return;
      this.setWinLabelVisible(false);
    });
    eventManager.addListener(EventTypes.SHOW_WIN_LABEL, this.setWinLabelVisible.bind(this, true));
    eventManager.addListener(EventTypes.UPDATE_TOTAL_WIN_VALUE, this.updateTotalWinValue.bind(this));
    eventManager.addListener(EventTypes.UPDATE_WIN_VALUE, this.updateWinValue.bind(this));
    eventManager.addListener(EventTypes.UPDATE_USER_BALANCE, this.updateUserBalance.bind(this));
    eventManager.addListener(EventTypes.UPDATE_BET, () => this.updateBetAmount(setBetAmount()));
    eventManager.addListener(
      EventTypes.UPDATE_FREE_ROUND_BONUS_TOTAL_WIN_VALUE,
      this.updateFreeRoundBonusTotalWinValue.bind(this),
    );
    eventManager.addListener(EventTypes.UPDATE_FREE_ROUNDS_LEFT, this.updateFreeRoundsAmount.bind(this));
    eventManager.addListener(EventTypes.SET_IS_FREE_ROUND_BONUS, (isFreeRoundBonus: boolean) => {
      this.changeFreeRoundAmounts(isFreeRoundBonus);
    });

    this.getUserBalance();
    this.getBetAmount();
    this.getWinAmount();
    window.addEventListener('message', (event) => {
      if (event.data?.name === 'getBalance') {
        this.getUserBalance('network-only');
      }
    });
  }

  private updateFreeRoundsAmount(amount: number): void {
    this.freeRoundsAmount.text = this.createText('freeRounds', amount);
  }

  private getBetAmount(): void {
    client.watchQuery<{ betAmount: number }>({ query: getBetAmountGql }).subscribe(({ data }) => {
      this.updateBetAmount(data.betAmount);
    });
  }

  private getWinAmount(): void {
    client.watchQuery<{ winAmount: number }>({ query: getWinAmountGql }).subscribe(({ data }) => {
      this.updateWinAmount(data.winAmount);
    });
  }

  private getUserBalance(fetchPolicy?: FetchPolicy): void {
    client
      .query({
        query: getUserGql,
        fetchPolicy,
      })
      .then((res) => {
        eventManager.emit(EventTypes.UPDATE_USER_BALANCE, res.data.user.balance);
      });
  }

  private updateUserBalance(balance?: { currency: string; amount: number }): void {
    if (!balance) return;
    if (!this.isInitialized) {
      this.currency = balance.currency;
      this.updateBetAmount(setBetAmount());
      this.updateWinAmount(setWinAmount());

      this.isInitialized = true;
    }
    this.updateBalance(balance.amount);
  }

  private createText(key: 'balance' | 'win' | 'bet' | 'totalWin' | 'freeRounds', value: number | string): string {
    return `${i18n.t(key).toUpperCase()}: ${value}`;
  }

  private setWinLabelVisible(visible: boolean): void {
    this.win.visible = visible;
  }

  private updateWinValue(newValue: string): void {
    if (setIsFreeRoundBonus()) return;
    if (setUserLastBetResult().coinValue === setCoinValue()) {
      this.setWinLabelVisible(true);
      this.win.text = `${this.createText('win', newValue)}`;
    }
  }

  private updateTotalWinValue(newValue: number): void {
    if (setIsFreeRoundBonus()) return;
    this.setWinLabelVisible(true);
    const coinValue = setCurrentBonus().isActive ? setCurrentBonus().coinValue : setCoinValue();
    this.win.text = this.createText(
      'totalWin',
      formatNumber(this.currency, normalizeCoins(newValue, coinValue), showCurrency(this.currency)),
    );
    if (newValue === 0) {
      this.setWinLabelVisible(false);
    }
  }

  private updateFreeRoundBonusTotalWinValue(newValue: number): void {
    if (!setIsFreeRoundBonus()) return;
    const coinValue = setCurrentBonus().isActive ? setCurrentBonus().coinValue : setCoinValue();
    this.setWinLabelVisible(true);
    this.win.text = this.createText(
      'totalWin',
      formatNumber(this.currency, normalizeCoins(newValue, coinValue), showCurrency(this.currency)),
    );
  }

  private resize(width: number, height: number): void {
    const containerHeight = calcBottomContainerHeight(width, height);
    const mobilePortrait = isMobilePortrait(width, height);

    this.rect.clear();
    this.rect.beginFill(0x663511, 0.46);
    this.rect.drawRect(0, 0, width, containerHeight);
    this.rect.endFill();

    const infoBtnHeight = height * (mobilePortrait ? 0.025 : 0.04);
    const padding = width * 0.02;
    this.infoBtn.height = infoBtnHeight;
    this.infoBtn.width = infoBtnHeight;
    this.infoBtn.x = padding + this.infoBtn.width / 2;

    const infoBtnOffset = this.infoBtn.width + 15;

    this.maxFontHeight = mobilePortrait ? containerHeight * 0.6 : containerHeight * 0.8;
    this.maxFontWidth = mobilePortrait ? (width - padding * 3) / 2 : (width - padding * 3 - infoBtnOffset) / 3;

    updateTextScale(this.win, this.maxFontWidth, this.maxFontHeight);
    updateTextScale(this.bet, this.maxFontWidth, this.maxFontHeight);
    updateTextScale(this.balance, this.maxFontWidth, this.maxFontHeight);
    updateTextScale(this.freeRoundsAmount, this.maxFontWidth, this.maxFontHeight);

    if (mobilePortrait) {
      this.infoBtn.y = containerHeight / 4;
      this.balance.position.set(padding, containerHeight * (3 / 4));
      this.freeRoundsAmount.position.set(padding, containerHeight * (3 / 4));
      this.win.position.set(width / 2, containerHeight / 4);
      this.bet.position.set(width - padding, containerHeight * (3 / 4));
    } else {
      this.infoBtn.y = containerHeight / 2;
      this.balance.position.set(padding + infoBtnOffset, containerHeight / 2);
      this.freeRoundsAmount.position.set(padding + infoBtnOffset, containerHeight / 2);
      this.win.position.set((width + infoBtnOffset) / 2, containerHeight / 2);
      this.bet.position.set(width - padding, containerHeight / 2);
    }

    this.pivot.set(0, containerHeight);
    this.position.set(0, height);
  }

  private updateBalance(amount: number): void {
    this.balance.text = this.createText(
      'balance',
      formatNumber(this.currency, normalizeBalance(amount), showCurrency(this.currency)),
    );
    updateTextScale(this.balance, this.maxFontWidth, this.maxFontHeight);
  }

  private updateWinAmount(amount: number): void {
    if (setIsFreeRoundBonus()) return;
    if ((!setIsProcessToGame() && !setBrokenGame()) || setGameMode() === GameMode.BUY_FEATURE) {
      this.win.text = this.createText(
        'win',
        formatNumber(this.currency, normalizeCoins(countCoins({ totalAmount: amount })), showCurrency(this.currency)),
      );
      updateTextScale(this.win, this.maxFontWidth, this.maxFontHeight);
      return;
    }
    if (setBrokenGame()) return;
    if (setGameMode() !== GameMode.REGULAR) return;
    const coins = setUserLastBetResult().id ? setUserLastBetResult().coinValue : setCoinValue();
    this.win.text = this.createText(
      'win',
      formatNumber(
        this.currency,
        normalizeCoins(countCoins({ totalAmount: amount }), coins),
        showCurrency(this.currency),
      ),
    );
    updateTextScale(this.win, this.maxFontWidth, this.maxFontHeight);
  }

  private updateBetAmount(amount: number): void {
    this.bet.text = this.createText(
      'bet',
      formatNumber(this.currency, normalizeCoins(countCoins({ totalAmount: amount })), showCurrency(this.currency)),
    );
    updateTextScale(this.bet, this.maxFontWidth, this.maxFontHeight);
  }

  private changeFreeRoundAmounts(showFreeRoundAmounts: boolean) {
    if (showFreeRoundAmounts) {
      this.balance.visible = false;
      this.freeRoundsAmount.visible = true;
      this.setWinLabelVisible(false);
    } else {
      this.balance.visible = true;
      this.freeRoundsAmount.visible = false;
      this.setWinLabelVisible(true);
    }
  }
}

export default BottomContainer;
